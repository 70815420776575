import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Center,
    Button,
    IconButton,
    Input,
    InputLeftElement,
    InputGroup,
    Select,
    TableContainer,
    Table,
    Th,
    Td,
    Tr,
    Thead,
    Tbody,
    Tfoot,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useToast
} from '@chakra-ui/react'
import { FaMapLocationDot } from "react-icons/fa6";
import { CalendarIcon, WarningIcon } from '@chakra-ui/icons';
import { FaTrashAlt } from 'react-icons/fa';
import { FaHouseFlag, FaPlus } from 'react-icons/fa6';
import { MdEdit } from 'react-icons/md';
import {
    COORDINATES,
    HOMES,
    GET,
    DEFAULT_GET_TIMEOUT,
    ID,
    POST,
    LOAN_TYPE,
    DOWN_PAYMENT_PERCENT,
    IS_VERIFIED,
    DELETE,
    DEFAULT_DELETE_TIMEOUT,
    MONTHLY_HOA_FEE,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    PURCHASE_PRICE,
    PROPERTY_TYPE,
    DEFAULT_POST_TIMEOUT,
    ADDITIONAL_ANNUAL_FEES,
    RENOVATION_COSTS,
    MAINTENANCE_PERCENT
} from '../../../utils/constants.js';
import { 
    checkRequireBuyerSignUp,
    convertLoanTypeEnumToReadableText,
    dollarfyPrice,
    isValidCoordinates, prettifyPropertyType } from '../../../utils/utility.js';
import { axiosGetUser, axiosGetBuyer } from '../../common/methods/methods.js';
import { dotPulse } from 'ldrs';

import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';
import BuyerDashboardParentPage from '../utils/BuyerDashboardParentPage.js';
import PropertyCards from './PropertyCards.js';
import MainPanel from '../../common/MainPanel.js';
import AddHomeModal from './AddHomeModal.js';
import EditHomeModal from './EditHomeModal.js';
import BuyerSignUpInformationModal from '../utils/BuyerSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function BuyersHomesDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [properties, setProperties] = useState([]);
    const [loans, setLoans] = useState([]);
    
    // Control our modals and their visibility.
    const [showAddHomeModal, setShowAddHomeModal] = useState(false);
    const [showEditHomeModal, setShowEditHomeModal] = useState(false);

    const [selectedHome, setSelectedHome] = useState(null);

    //State we set whenever we want to fetch updated properties.
    const [triggerFetchProperties, setTriggerFetchProperties] = useState(false);
    const [showPropertiesTableModal, setShowPropertiesTableModal] = useState(false);
    const [yearsLivingInputValue, setYearsLivingInputValue] = useState(null);
    const [propertiesLoading, setPropertiesLoading] = useState(true);
    const [finishSignUpFormBuyer, setFinishSignUpFormBuyer] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const toast = useToast();
    dotPulse.register();

    const urlParams = useParams();
    const userId = urlParams[ID];

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            // TODO
        });
    }, [userId]);

    useEffect(() => {
        axiosGetBuyer(userId).then(buyer => {
            if (buyer && checkRequireBuyerSignUp(buyer) === true) {
                setFinishSignUpFormBuyer(buyer);
            } 
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId]);

     // On page load, fetch all the properties associated with this user.
    // We also pull from the server on triggerFetchProperties state change.
    // triggerFetchProperties is an explicit state to use to trigger this function.
    // useLayoutEffect since we need the dom rendered before we can add the
    // map container.
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/properties/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
            withCredentials: true,
        }).then(response => {
            let responseProperties = response.data;
            setProperties(responseProperties);
            if (responseProperties.length > 0 && selectedHome === null) {
                setSelectedHome(responseProperties[0]);                
            }
        }).catch(error => {
            // TODO: Handle our error codes.
        })
        setPropertiesLoading(false);
    }, [userId, selectedHome, triggerFetchProperties]);


    // On page load, fetch all the loans associated with this user.
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/loans/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
            withCredentials: true,
        }).then(response => {
            let responseLoans = response.data;
            setLoans(responseLoans);
        }).catch(error => {
            // TODO: Handle our error codes.
        })
    }, [userId]);

    const handleDeleteProperty = () => {

        axios({
            method: DELETE,
            url: '/api/v1/user/buyer/properties/' + user[ID] + '/' + selectedHome[ID],
            timeout: DEFAULT_DELETE_TIMEOUT,
            withCredentials: true,
        }).then(response => {
            if (response.data === 200) {
                // Successful delete, so we need to remove the loan from our state.
                toast({
                    title: 'Deleted Home',
                    description: "Successfully deleted your property",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                setTriggerFetchProperties(!triggerFetchProperties);
            }
        }).catch(error => {
            // TODO
        })
    }

    const handleResendVerificationEmail = () => {

        axios({
            method: POST,
            url: '/api/v1/account-verification/resend/' + userId,
            timeout: DEFAULT_POST_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                toast({
                    title: "Account verification email resent",
                    description: "We've re-sent your account verification email. Do not share your link with anyone else.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            if (500 === error.response.status) {
                toast({
                    title: "Internal Server Error",
                    description: "We've encountered an error sending out a new account verification email. Please try again in 1 minute",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            } else if (429 === error.response.status) {
                toast({
                    title: "Too many requests",
                    description: "Please wait 1 minute between requesting a new account verification email",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            }
        })
    }

    const LoanOptions = () => {
        
        let options = [];

        if (!loans) {
            return (
                <Select placeholder="No loans found" size='sm' variant='outline' style={{
                    backgroundColor: "white",
                    border: "none",
                    // border: "1px solid #cccccc",
                    borderRadius: "4px",
                    boxShadow: "none",
                    margin: "10px 0px 0px 0px",
                    width: "100%",
                }}/>
            );
        }
        for (var i = 0; i < loans.length; i++) {
            let loan = loans[i];
            options.push(
                <option value={loan[ID]}>{loan[DOWN_PAYMENT_PERCENT]}% Down {convertLoanTypeEnumToReadableText(loan[LOAN_TYPE])}</option>
            );
        }
        return (
            <Select size='sm' variant='outline' className="default_input_full" style={{
                margin: "5px 0px 0px 0px",
                width: "100%",
            }}>
                <option hidden disabled value='Loan'>Loan</option>
                {options}
            </Select>
        );
    }

    const PropertiesTableModal = () => {
        var renderedProperties = [];
        for (var i = 0; i < properties.length; i++) {
            let property = properties[i];
            renderedProperties.push(
                <Tr key={property[ID] + "properties_table_modal_row"}>
                    <Td>{property[ADDRESS_LINE_ONE]} {property[ADDRESS_LINE_TWO]}</Td>
                    <Td>{prettifyPropertyType(property[PROPERTY_TYPE])}</Td>
                    <Td>${dollarfyPrice(property[PURCHASE_PRICE])}</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                </Tr>
            )
        }

        return (
            <Modal size='6xl' isOpen={showPropertiesTableModal} onClose={() => setShowPropertiesTableModal(false)} isCentered >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader style={{
                        paddingBottom: "5px",
                    }}>
                        Properties
                    </ModalHeader>
                    <ModalCloseButton onClose={() => setShowPropertiesTableModal(false)} onMouseDown={() => setShowPropertiesTableModal(false)}/>
                    <ModalBody style={{
                        minHeight: "500px",
                        paddingTop: "0px",
                    }}>
                        <TableContainer style={{
                            borderRadius: "6px",
                            margin: "15px 0px 0px 0px",
                        }}>
                            <Table variant='simple' size='sm' style={{
                            }}>
                                <Thead>
                                    <Tr>
                                        <Th>Address</Th>
                                        <Th>Property Type</Th>
                                        <Th>Property Value</Th>
                                        <Th>Closing Costs</Th>
                                        <Th>Annual Total Costs</Th>
                                        <Th>Equivalent Monthly Rent</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {renderedProperties}
                                </Tbody>
                                <Tfoot>
                                </Tfoot>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }

    const handleYearsLivingHere = (event) => {
        setYearsLivingInputValue(event.target.value);
        return;
    }

    const renderFilters = () => {
        return (
            <div className="home_page__filters">
                <div className="home_page__filters_subbox">
                    <p style={{
                        fontSize: "1.5em",
                        fontWeight: "bold",
                    }}>
                        Homes
                    </p>
                    <div className="homes_page__filters_property_type">
                        <div className="homes_page__filters_buttons_mobile_parent">
                            {/* <Button className="home_page__table_icon hover" onMouseDown={() => setShowPropertiesTableModal(true)} size='sm' leftIcon={<FaTableList/>} style={{
                                backgroundColor: "transparent",
                                border: "1px solid #cccccc",
                                borderRadius: "4px",
                                margin: "0px 10px 0px 0px",
                            }}>
                                Table
                            </Button> */}
                            {
                                selectedHome ?
                                <IconButton className="home_page__table_icon hover" onMouseDown={() => handleDeleteProperty()} size='sm' icon={<FaTrashAlt/>} style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #cccccc",
                                    borderRadius: "4px",
                                    margin: "0px 10px 0px 0px",
                                }}/> : null
                            }
                            {
                                selectedHome ?
                                <IconButton className="home_page__table_icon hover" onMouseDown={() => setShowEditHomeModal(true)} size='sm' icon={<MdEdit/>} style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #cccccc",
                                    borderRadius: "4px",
                                    margin: "0px 10px 0px 0px",
                                }}/> : null
                            }
                            <Button
                                className="positive_button hover" 
                                leftIcon={<FaPlus/>} size='sm' onMouseDown={() => setShowAddHomeModal(true)} style={{
                                backgroundColor: "#05b96b",
                                borderRadius: "4px",
                                color: "white",
                            }}>
                                Add Home
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="homes_page__filters_buttons_web_parent">
                    {/* <Button className="hover" onMouseDown={() => setShowPropertiesTableModal(true)} size='sm' leftIcon={<FaTableList/>} style={{
                        backgroundColor: "#e0e0e0",
                        // border: "1px solid #cccccc",
                        borderRadius: "4px",
                        margin: "0px 10px 0px 0px",
                    }}>
                        Table
                    </Button> */}
                    {
                        selectedHome ?
                        <IconButton className="home_page__table_icon hover" onMouseDown={() => handleDeleteProperty()} size='sm' icon={<FaTrashAlt/>} style={{
                            backgroundColor: "grey",
                            // border: "1px solid #cccccc",
                            borderRadius: "50px",
                            color: "white",
                            margin: "0px 10px 0px 0px",
                        }}/> : null
                    }
                    {
                        selectedHome ?
                        <IconButton className="home_page__table_icon hover" onMouseDown={() => setShowEditHomeModal(true)} size='sm' icon={<MdEdit/>} style={{
                            backgroundColor: "#e0e0e0",
                            // border: "1px solid #cccccc",
                            borderRadius: "50px",
                            margin: "0px 10px 0px 0px",
                        }}/> : null
                    }
                    <Button
                        className="positive_button hover" 
                        leftIcon={<FaPlus/>} size='sm' onMouseDown={() => setShowAddHomeModal(true)} style={{
                        backgroundColor: "#05b96b",
                        borderRadius: "6px",
                        color: "white",
                    }}>
                        Add Home
                    </Button>
                </div>
            </div>
        );
    }

    if (userLoading || propertiesLoading) return;

    return (
        <BuyerDashboardParentPage user={user} tab={HOMES}>
            {
                propertiesLoading || finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormBuyer ?
                        <BuyerSignUpInformationModal
                            user={finishSignUpFormBuyer}
                            setFinishSignUpFormBuyer={setFinishSignUpFormBuyer}
                        />: null
                    }
                    <MainPanel>
                        {
                            user && user[IS_VERIFIED] === false ?
                            <div className="home_page__verify_email_parent">
                                <WarningIcon style={{
                                    height: "20px",
                                    margin: "auto 10px auto 0px",
                                    width: "20px",
                                }}/>
                                <p style={{
                                    lineHeight: "50px",
                                    overflow: "hidden",
                                    textOverflow: "ellipses",
                                }}>
                                    Please verify your account. Didn't get a verification email?
                                </p>
                                <p 
                                    onMouseDown={() => handleResendVerificationEmail()}
                                    style={{
                                        cursor: "pointer",
                                        lineHeight: "50px",
                                        margin: "0px 0px 0px 10px",
                                        textDecoration: "underline",
                                        overflow: "hidden",
                                        textOverflow: "ellipses",
                                    }}
                                >
                                    Re-send verification email
                                </p>
                            </div> :
                            null
                        }
                        <div style={{
                            height: "calc(100vh - 150px) !important",
                            overflow: "scroll",
                            width: "100%",
                        }}>
                            {
                                showEditHomeModal ?
                                <EditHomeModal
                                    userId={userId}
                                    property={selectedHome}
                                    setSelectedHome={(home) => setSelectedHome(home)}
                                    showEditHomeModal={showEditHomeModal}
                                    setTriggerFetchProperties={() => setTriggerFetchProperties(!triggerFetchProperties)}
                                    setShowEditHomeModal={() => setShowEditHomeModal()}
                                /> : null
                            }
                            {
                                showAddHomeModal ?
                                <AddHomeModal
                                    userId={userId}
                                    showAddHomeModal={showAddHomeModal}
                                    setTriggerFetchProperties={() => setTriggerFetchProperties(!triggerFetchProperties)}
                                    setShowAddHomeModal={() => setShowAddHomeModal()}
                                /> : null
                            }
                            <PropertiesTableModal/>
                            {
                                properties && properties.length > 0 ?
                                <>
                                    {renderFilters()}
                                    <div style={{
                                        display: "flex",
                                        overflow: "scroll",
                                    }}>
                                        <PropertyCards user={user} properties={properties} setSelectedHomeCallback={(home) => {setSelectedHome(home)}}/>
                                    </div>
                                    <div>
                                        <p style={{
                                            fontSize: "1.4em",
                                            fontWeight: "bold",
                                            margin: "0px 0px 10px 0px",
                                        }}>
                                            Home Information
                                        </p>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}>
                                            <div style={{
                                                height: 'calc(100vh - 350px)',
                                                minHeight: "350px",
                                            }}>
                                                <div style={{
                                                    backgroundColor: "#f4f4f4",
                                                    borderRadius: "12px",
                                                    height: "190px",
                                                    minHeight: "calc((350px - 20px)/2)",
                                                    padding: "15px 25px 15px 25px",
                                                    width: "250px",
                                                }}>
                                                    <p style={{
                                                        color: "black",
                                                        fontWeight: "bold",
                                                        fontSize: "1.0em",
                                                    }}>
                                                        Modeling
                                                    </p>
                                                    <div style={{
                                                        margin: "5px 0px 0px 0px",
                                                    }}>
                                                        <p style={{
                                                            fontSize: "0.9em",
                                                        }}>
                                                            Choose a loan
                                                        </p>
                                                        <LoanOptions/>
                                                    </div>
                                                    <div style={{
                                                        margin: "10px 0px 0px 0px",
                                                    }}>
                                                        <p style={{
                                                            fontSize: "0.9em",
                                                        }}>
                                                            How long will you live here?
                                                        </p>
                                                        <InputGroup style={{
                                                            margin: '5px 0px 0px 0px',
                                                            position: "relative",
                                                        }}>
                                                            <InputLeftElement pointerEvents='none'>
                                                                <CalendarIcon color='gray' style={{
                                                                    margin: "-7.5px 5px 0px 0px",
                                                                }}/>
                                                            </InputLeftElement>
                                                            <Input
                                                                id="homes_side_panel__living_here_input"
                                                                className="default_input_full"
                                                                onChange={handleYearsLivingHere}
                                                                placeholder={'1 year'} 
                                                                size='sm'
                                                            >
                                                            </Input>
                                                            {
                                                                yearsLivingInputValue ? 
                                                                <div style={{
                                                                    backgroundColor: "#eeeeee",
                                                                    borderRadius: "12px",
                                                                    fontSize: "0.9em",
                                                                    padding: "1.5px 10px 1.5px 10px",
                                                                    position: "absolute",
                                                                    right: "3px",
                                                                    top: "4px",
                                                                    zIndex: "2",
                                                                }}>
                                                                    {yearsLivingInputValue === "1" ? "year" : "years"}
                                                                </div> : null
                                                            }
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    backgroundColor: "#f4f4f4",
                                                    borderRadius: "12px",
                                                    padding: "15px 25px 15px 25px",
                                                    height: 'calc((100vh - 350px - 160px))',
                                                    minHeight: "calc((350px - 20px)/2)",
                                                    margin: "20px 0px 0px 0px",
                                                    overflow: "scroll",
                                                    width: "250px",
                                                }}>
                                                    <p style={{
                                                        color: "grey",
                                                        fontSize: "0.7em",
                                                    }}>
                                                        PROPERTY TYPE
                                                    </p>
                                                    <p style={{
                                                        fontSize: "0.9em"
                                                    }}>
                                                        {prettifyPropertyType(selectedHome[PROPERTY_TYPE])}
                                                    </p>
                                                    <p style={{
                                                        color: "grey",
                                                        fontSize: "0.7em",
                                                        margin: "10px 0px 0px 0px",
                                                    }}>
                                                        ADDITIONAL FEES
                                                    </p>
                                                    <p style={{
                                                        fontSize: "0.9em"
                                                    }}>
                                                        ${dollarfyPrice(selectedHome[ADDITIONAL_ANNUAL_FEES])} / year
                                                    </p>
                                                    <p style={{
                                                        color: "grey",
                                                        fontSize: "0.7em",
                                                        margin: "10px 0px 0px 0px",
                                                    }}>
                                                        RENOVATION COSTS
                                                    </p>
                                                    <p style={{
                                                        fontSize: "0.9em"
                                                    }}> 
                                                        ${dollarfyPrice(selectedHome[RENOVATION_COSTS])}
                                                    </p>
                                                    <p style={{
                                                        color: "grey",
                                                        fontSize: "0.7em",
                                                        margin: "10px 0px 0px 0px",
                                                    }}>
                                                        HOA
                                                    </p>
                                                    <p style={{
                                                        fontSize: "0.9em"
                                                    }}>
                                                        ${dollarfyPrice(selectedHome[MONTHLY_HOA_FEE])} / month
                                                    </p>
                                                    <p style={{
                                                        color: "grey",
                                                        fontSize: "0.7em",
                                                        margin: "10px 0px 0px 0px",
                                                    }}>
                                                        MAINTENANCE
                                                    </p>
                                                    <p style={{
                                                        fontSize: "0.9em"
                                                    }}>
                                                        {dollarfyPrice(selectedHome[MAINTENANCE_PERCENT])}%
                                                    </p>
                                                </div>
                                            </div>
                                            <div id="map-container" style={{ 
                                                display: selectedHome !== null && isValidCoordinates(selectedHome[COORDINATES]) ? "block" : "none",
                                                borderRadius: "12px",
                                                height: 'calc(100vh - 300px)',
                                                minHeight: "350px",
                                                width: "calc(100% - 250px - 20px)", // - 250px - 20px
                                            }}>
                                                <div id="map" style={{ 
                                                    borderRadius: "8px",
                                                    height: 'inherit',
                                                    minHeight: "350px",
                                                    width: '100%'
                                                }}/>
                                            </div> 
                                            <div style={{
                                                display: selectedHome !== null && isValidCoordinates(selectedHome[COORDINATES]) ? "none" : "block",
                                                backgroundColor: "#f0f0f0",
                                                borderRadius: "12px",
                                                height: 'calc(100vh - 350px)',
                                                position: "relative",
                                                width: "calc(100% - 250px - 250px - 20px - 20px)",
                                            }}>
                                                <Center id="map">
                                                    <FaMapLocationDot style={{
                                                        color: "grey",
                                                        height: "25px",
                                                        top: "calc(50% - 25px)",
                                                        position: "absolute",
                                                        width: "25px",
                                                    }}/>
                                                    <p style={{
                                                        color: "grey",
                                                        fontSize: "0.8em",
                                                        margin: "5px 0px 0px 0px",
                                                        position: "absolute",
                                                        top: "50%",
                                                    }}>
                                                        Invalid address
                                                    </p>
                                                </Center>
                                            </div>
                                            {/* <div style={{
                                                backgroundColor: "#f4f4f4",
                                                borderRadius: "12px",
                                                padding: "15px 25px 15px 25px",
                                                width: "250px",
                                            }}>
                                                <p style={{
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    fontSize: "1.0em",
                                                }}>
                                                    Results
                                                </p>
                                                <div>

                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </> :
                                <NoObjectsDisplay
                                    icon={FaHouseFlag}
                                    buttonCallback={() => setShowAddHomeModal(true)}
                                    buttonText={'Add Home'}
                                    titleText={'NO PROPERTIES FOUND'}
                                    subtitleText={'Add an interested property here to track property options. Add multiple to easily compare between them.'}
                                />
                            }
                        </div>
                    </MainPanel>
                </>
            }
        </BuyerDashboardParentPage>
    );
}

export default BuyersHomesDashboardPage;